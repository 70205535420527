import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, shareReplay } from 'rxjs';
import { AppConfig } from '../../../@common/configuration/app-config.service';
import { Page } from '../../../@common/entities/page.interface';
import { Logger } from '../../../@common/log/logger';
import { PaginationUtility } from '../../../@common/util/pagination.utility';
import { Video } from '../../video/entities/video.interface';
import { Category } from '../entities/category.interface';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    private http = inject(HttpClient);
    private logger = inject(Logger);
    private appConfig = inject(AppConfig);

    getTopCategories(): Observable<Category[]> {
        return this.http.get<Page<Category>>(
            `${ this.appConfig.apiUrl }/category/top_categories/`,
            { withCredentials: true }
        )
            .pipe(
                map(res => res.results ?? []),
                shareReplay(1),
                catchError(e => {
                    this.logger.apiError('Get top categories failed', e);
                    return of([]);
                })
            );
    }

    getAllCategories(
        limit: number,
        page: number,
        sort: string,
        showLectures: boolean
    ): Observable<Page<Category>> {
        if (sort !== 'alphabet' && sort !== 'number_category') {
            sort = 'alphabet';
        }

        return this.http.get<Page<Category>>(
            `${ this.appConfig.apiUrl }/category/${ sort }/?limit=${ limit }&offset=${ PaginationUtility.offset(limit, page) }&show_lectures=${ showLectures }&show_image=false`,
            { withCredentials: true }
        ).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get all categories failed', e);
                return of({});
            })
        );
    }

    getLecturesOfCategory(
        id: string,
        hits: string,
        language: string,
        limit: number,
        page: number,
        sort: string,
        type: string,
        year: string
    ): Observable<Page<Video> & { category: string }> {
        let sortBy: string;
        if (sort === 'views') {
            sortBy = 'views';
        } else if (sort === 'published') {
            sortBy = 'published';
        } else if (sort === 'starred') {
            sortBy = 'starred';
        } else if (sort === 'title') {
            sortBy = 'title';
        } else {
            sortBy = '';
        }

        return this.http.get<Page<Video> & { category: string }>(
            `${ this.appConfig.apiUrl }/category/${ id }/filter_lectures/?sort=${ sortBy }&hits=${ hits ?? '' }&type=${ type ?? '' }&year=${ year ?? '' }&language=${ language ?? '' }&limit=${ limit ?? '' }&offset=${ PaginationUtility.offset(limit, page) }`,
            { withCredentials: true }
        ).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get lectures of category failed', e);
                return of({}) as Observable<Page<Video> & { category: string }>;
            })
        );
    }

}
